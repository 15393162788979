<template>
  <v-responsive>
    <SubMenuMypageMobile v-if="$vuetify.breakpoint.smAndDown"></SubMenuMypageMobile>
    <v-container :fluid="$vuetify.breakpoint.lgAndDown" style="max-width:1440px;"> 
      <v-row>
        <v-col cols="12" sm="3" flat tile v-if="$vuetify.breakpoint.mdAndUp">
          <v-container class="pa-0">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0 pl-6" flat tile>
                <h2
                  class="
                    bx_pagetitle
                    float-left
                    mt-5
                    mb-3
                    blue--text
                    text--darken-4
                  "
                >
                  {{ breadcrumbs[1].text }}
                </h2>
              </v-card>
            </v-card>
          </v-container>
          <v-divider></v-divider>
          
          <SubMenuMypage></SubMenuMypage>





        </v-col>
        <v-col cols="12" sm="12" md="9" :class="$vuetify.breakpoint.smAndDown? 'mt-0 pt-0':''">
          <v-container class="pa-0" v-if="$vuetify.breakpoint.mdAndUp">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0" flat tile>
                <h2 class="bx_pagetitle float-left mt-5 mb-3">
                  {{ breadcrumbs[2].text }}
                </h2>
              </v-card>
              <v-card class="pa-0" flat tile v-if="$vuetify.breakpoint.mdAndUp">
                <v-breadcrumbs :items="breadcrumbs" class="float-right mt-2">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-card>
            </v-card>
          <v-divider></v-divider>
          </v-container>
          

                <v-responsive class="mt-10">
                    <h3>나의 조합관리</h3>
                    <v-sheet outlined class=" mt-1 ">
                        <v-simple-table >
                          <template >
                            <thead>
                              <tr>
                                <th class="text-center datatable_header" style="font-size:13px;">
                                  1등
                                </th>
                                <th class="text-center datatable_header" style="font-size:13px;">
                                  2등
                                </th>
                                <th class="text-center datatable_header" style="font-size:13px;">
                                  3등
                                </th>
                                <th class="text-center datatable_header" style="font-size:13px;">
                                  4등
                                </th>
                                <th class="text-center datatable_header" style="font-size:13px;">
                                  5등
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                              >
                                <td class="text-center">
                                  <WidgetMyWonCount grade="1"/>
                                  회</td>
                                <td class="text-center">
                                  <WidgetMyWonCount grade="2"/>회</td>
                                <td class="text-center">
                                  <WidgetMyWonCount grade="3"/>회</td>
                                <td class="text-center">
                                  <WidgetMyWonCount grade="4"/>회</td>
                                <td class="text-center">
                                  <WidgetMyWonCount grade="5"/>회</td>
                                
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                    </v-sheet>
                </v-responsive>

  

          <v-responsive class="mt-10">
            <h3>보관함 조회내역</h3>
                        <v-radio-group
                          v-model="grade"
                          row
                        >
                          <v-radio
                            label="전체"
                            value="all"
                          ></v-radio>
                          <v-radio
                            label="당첨"
                            value="won"
                          ></v-radio>
                          <v-radio
                            label="낙첨"
                            value="fail"
                          ></v-radio>
                        </v-radio-group>
              <v-sheet outlined class=" mt-1 ">
                  <v-simple-table class="custom_table">
                    <template >
                      <thead>
                        <tr>
                          <th class="text-center datatable_header" style="font-size:13px;padding:0;">
                            회차
                          </th>
                          <th class="text-center datatable_header" style="font-size:13px;">
                            조합번호
                          </th>
                          <th class="text-center datatable_header" style="font-size:13px;" v-if="$vuetify.breakpoint.mdAndUp">
                            발급일
                          </th>
                          <th class="text-center datatable_header" style="font-size:13px;padding:0;">
                            당첨
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="items">
                        <tr
                          v-for="item in items"
                          :key="item.name"
                        >
                          <td class="text-center" width="45">{{item.fields.no}}</td>
                          <td class="text-center">
                                          <WidgetLottoNumber :number="getLottoArray(item.fields.nums)" style="text-align:center;" viewtype="small"></WidgetLottoNumber>
                          </td>
                          <td class="text-center" v-if="$vuetify.breakpoint.mdAndUp">{{$moment(item.date).format("YYYY-MM-DD")}}</td>
                          <td class="text-center" width="45">
                            <template v-if="item.fields.grade > 0">
                            {{item.fields.grade}}등
                            </template>
                            <template v-if="item.fields.grade == -1">
                            낙첨
                            </template>
                            <template v-if="!item.fields.grade">
                            발표예정
                            </template>
                          </td>
                          
                        </tr>
                      </tbody>
                      <tbody v-if="items.length < 1">
                        <tr
                        >
                          <td class="text-center" colspan="5">
                            
                            <v-responsive class="my-5">
                            조회된 번호조합이 없습니다.<br>                    
                            
                            <v-btn
                              color="blue darken-4 white--text mt-2"
                            to="/analysis/auto"
                            >
                              오늘의 행운번호 20수 받기
                            </v-btn>
                            </v-responsive>


                          </td>
                          
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
              </v-sheet>
          </v-responsive>


    <v-pagination
    class="mt-10
    "
      v-model="page"
      :length="lastpage"
      prev-icon="mdi-menu-left"
      next-icon="mdi-menu-right"
    ></v-pagination>



        </v-col>
      </v-row>
    </v-container>
  </v-responsive>
</template>

<script>
import SubMenuMypage from '../components/SubMenuMypage.vue';
//import HelloWorld from "../components/HelloWorld";

export default {
  name: "Mypage",

  components: {
    SubMenuMypage
    //HelloWorld,
  },
  data: () => ({
    breadcrumbs: [
      { text: "홈" },
      { text: "마이페이지" },
      { text: "로또 보관함" },
      //{ text: "당첨 통계", icon: "mdi-menu-right" },
    ],

      items: [],

      thispage: null,
      lastpage: null,
      total: null,

      loading: true,
      firstLoad: true,




    lottoNumbers:  
                [16, 18, 19, 27, 29, 40]
      ,
    selectedItem: 0,
        page: 1,
        freeboard: [
          {
            idx: 975,
            date: '2021.08.10',
            numbers: '1613373840+9',
            grade: '5',
          },
          {
            idx: 975,
            date: '2021.08.10',
            numbers: '1613373840+9',
            grade: '5',
          },
          {
            idx: 975,
            date: '2021.08.10',
            numbers: '1613373840+9',
            grade: '5',
          },
          {
            idx: 975,
            date: '2021.08.10',
            numbers: '1613373840+9',
            grade: '5',
          },
          {
            idx: 975,
            date: '2021.08.10',
            numbers: '1613373840+9',
            grade: '5',
          },
        ],
        grade: 'all',
  }),
  mounted() {
    //페이지 시작하면은 자동 함수 실행
    this.getList();
    console.log(this.firstLoad);
  },
  
  watch: {
    
    grade() {
      console.log("watch page");

      //카테고리를 이동하면서 발생되는 이벤트는 초기 로딩이 완료 된 후에 작동
      if (this.firstLoad === false) {
        this.items = [];
        this.getList();
      }
    },
    page() {
      console.log("watch page");

      //카테고리를 이동하면서 발생되는 이벤트는 초기 로딩이 완료 된 후에 작동
      if (this.firstLoad === false) {
        this.items = [];
        this.getList();
      }
    },

  },
  methods: {
    getLottoArray(lottoNumStr) {
      return lottoNumStr.split(',');
    },
    getType(type) {
      if(type=='paid'){
        return '유료';
      }
      if(type=='free'){
        return '무료';
      }
    },


    nextPage() {
      if (this.thispage >= this.lastpage) return false;

      let nextpage = this.thispage + 1;

      //console.log(nextpage);
      this.getList(nextpage);
    },

    getList(page) {
      if (!page) page = this.page;
      this.loading = true;

      this.$http
        .get(
          "/api/v1/number/?page=" +
            page +
            "&count=" +
            10 +
            "&grade=" +
            this.grade +
            "&parent=" +
            ""
        )
        .then((res) => {
          console.log(res);

          if (res.status === 200) {
            if (this.firstLoad) {
              this.items = res.data.items;
            } else {
              this.items = this.items.concat(res.data.items);
            }

            this.thispage = res.data.thispage;
            this.lastpage = res.data.lastpage;
            this.total = res.data.total;
            this.uid = res.data.uid;
            this.roles = res.data.roles;

            //this.paging = res.data.paging;
            //this.no = this.paging.totalCount - ((this.paging.page-1) * this.paging.ipp);

            //this.classes = res.data;
          } else {
            alert("실행중 실패했습니다.\n다시 이용해 주세요.");
          }

          if (this.firstLoad) this.firstLoad = false;
          this.loading = false;
        })
        .then((err) => {
          console.log(err);

          this.loading = false;
        });

    },

  },
};
</script>
<style scoped>
.custom_table td {padding:0 !important;}
</style>
